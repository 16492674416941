import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_BANKKS = "/bank";
const URL_SAVE_BANK = "/bank/create";
const URL_EDIT_BANK = "/bank/update";

export const getBankList = () => {
  return request(URL_GET_BANKKS, httpTypes.GET);
};

export const saveBankData = (body) => {
  return request(URL_SAVE_BANK, httpTypes.POST, body);
};

export const editBankData = (body) => {
  return request(URL_EDIT_BANK, httpTypes.PUT, body);
};

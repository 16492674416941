import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editCountryData,
  getCountriesList,
  saveCountryData,
} from "app/services/countriesService";

const initialState = {
  error: null,
  countries: [],
  country: null,
  loadingCountry: false,
  countrySave: null,
};

export const getCountries = createAsyncThunk("countries/get", async () => {
  const res = await getCountriesList();
  return res.data.result;
});

export const saveCountry = createAsyncThunk("countries/post", async (body) => {
  const res = await saveCountryData(body);
  return res.data.result;
});

export const editCountry = createAsyncThunk("countries/put", async (body) => {
  const res = await editCountryData(body);
  return res.data.result;
});

const countrySlice = createSlice({
  initialState,
  name: "countries",
  reducers: {
    setCountry(state, actions) {
      const { payload } = actions;
      state.country = payload;
    },
    resetCountry(state, actions) {
      state.country = null;
    },
    resetCountrySave(state, actions) {
      state.countrySave = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.loadingCountry = true;
      })
      .addCase(getCountries.fulfilled, (state, actions) => {
        state.loadingCountry = false;
        state.countries = actions.payload;
      })
      .addCase(getCountries.rejected, (state, actions) => {
        state.loadingCountry = false;
        state.countries = [];
        state.error = actions.error.message;
      })
      .addCase(saveCountry.pending, (state) => {
        state.loadingCountry = true;
      })
      .addCase(saveCountry.fulfilled, (state, actions) => {
        state.loadingCountry = false;
        state.countrySave = actions.payload;
      })
      .addCase(saveCountry.rejected, (state, actions) => {
        state.loadingCountry = false;
        state.error = actions.error.message;
      })
      .addCase(editCountry.pending, (state) => {
        state.loadingCountry = true;
      })
      .addCase(editCountry.fulfilled, (state, actions) => {
        state.loadingCountry = false;
        state.countrySave = actions.payload;
      })
      .addCase(editCountry.rejected, (state, actions) => {
        state.loadingCountry = false;
        state.error = actions.error.message;
      });
  },
});
export const { setCountry, resetCountry, resetCountrySave } =
  countrySlice.actions;
export const countryReducer = countrySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, actions) {
      const { payload } = actions;
      const findIndex = state.findIndex((item) => item.id === payload.id);

      if (findIndex !== -1) state[findIndex].qty++;
      else state.push({ ...payload, qty: 1 });
    },

    addToCartByQty(state, actions) {
      const { payload } = actions;
      const findIndex = state.findIndex((item) => item.id === payload.id);

      if (findIndex !== -1) state[findIndex].qty = payload.qty;
      else state.push({ ...payload, qty: payload.qty });
    },

    removeFromCart(state, actions) {
      const findIndex = state.findIndex((item) => item.id === actions.payload);
      if (findIndex !== -1) state.splice(findIndex, 1);
    },

    removeQtyFromCart(state, actions) {
      const findIndex = state.findIndex((item) => item.id === actions.payload);

      if (state[findIndex].qty > 1) state[findIndex].qty--;
      else state.splice(findIndex, 1);
    },

    clearCart(state) {
      state.splice(0, state.length);
    },
  },
});

export const {
  addToCart,
  addToCartByQty,
  removeFromCart,
  removeQtyFromCart,
  clearCart,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_TYPE_DOCUMENTS = "/typeDocument/alltypedocuments";
const URL_SAVE_TYPE_DOCUMENTS = "/typeDocument";

export const getTypeDocumentList = () => {
  return request(URL_GET_TYPE_DOCUMENTS, httpTypes.GET);
};

export const saveTypeDocumentData = (body) => {
  return request(URL_SAVE_TYPE_DOCUMENTS, httpTypes.POST, body);
};

export const editTypeDocumentData = (body) => {
  return request(URL_SAVE_TYPE_DOCUMENTS, httpTypes.PUT, body);
};

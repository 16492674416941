import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editCityData,
  getCityList,
  getCityListByState,
  saveCityData,
} from "app/services/cityService";

const initialState = {
  error: null,
  cities: [],
  loadingCity: false,
  city: null,
  saveCity: null,
};

export const getCitiesByState = createAsyncThunk(
  "citiesByState/get",
  async (stateId) => {
    const res = await getCityListByState(stateId);
    return res.data.result;
  },
);

export const getCities = createAsyncThunk("cities/get", async () => {
  const res = await getCityList();
  return res.data.result;
});

export const saveCity = createAsyncThunk("cities/post", async (body) => {
  const res = await saveCityData(body);
  return res.data.result;
});

export const editCity = createAsyncThunk("cities/put", async (body) => {
  const res = await editCityData(body);
  return res.data.result;
});

const citiesSlice = createSlice({
  initialState,
  name: "cities",
  reducers: {
    setCity(state, actions) {
      const { payload } = actions;
      state.city = payload;
    },
    resetCiy(state, actions) {
      state.city = null;
    },
    resetCities(state, actions) {
      state.cities = [];
    },
    resetCitySave(state, actions) {
      state.saveCity = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.loadingCity = true;
      })
      .addCase(getCities.fulfilled, (state, actions) => {
        state.loadingCity = false;
        state.cities = actions.payload;
      })
      .addCase(getCities.rejected, (state, actions) => {
        state.loadingCity = false;
        state.cities = [];
        state.error = actions.error.message;
      })
      .addCase(getCitiesByState.pending, (state) => {
        state.loadingCity = true;
      })
      .addCase(getCitiesByState.fulfilled, (state, actions) => {
        state.loadingCity = false;
        state.cities = actions.payload;
      })
      .addCase(getCitiesByState.rejected, (state, actions) => {
        state.loadingCity = false;
        state.cities = [];
        state.error = actions.error.message;
      })
      .addCase(saveCity.pending, (state) => {
        state.loadingCity = true;
      })
      .addCase(saveCity.fulfilled, (state, actions) => {
        state.loadingCity = false;
        state.saveCity = actions.payload;
      })
      .addCase(saveCity.rejected, (state, actions) => {
        state.loadingCity = false;
        state.error = actions.error.message;
      })
      .addCase(editCity.pending, (state) => {
        state.loadingCity = true;
      })
      .addCase(editCity.fulfilled, (state, actions) => {
        state.loadingCity = false;
        state.saveCity = actions.payload;
      })
      .addCase(editCity.rejected, (state, actions) => {
        state.loadingCity = false;
        state.error = actions.error.message;
      });
  },
});

export const { setCity, resetCiy, resetCitySave, resetCities } =
  citiesSlice.actions;
export const citiesReducer = citiesSlice.reducer;

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_ORDER_COMPANY = "/order/getOrderbycompany";
const URL_GET_ORDER_STORE = "/order/getOrderbystore";
const URL_GET_ORDER_PROVIDER = "/order/getOrderbyprovider";
const URL_GET_ORDER_EMPLOYEE = "/order/getOrderbyemployee";
const URL_SAVE_ORDER = "/order";
const URL_TOP_PRODUCTS = "/Order/get-top5invoiceditems";
const URL_COST_INVENTORY_SALES = "/Order/getsCostInventoryAndTotalSales";
const URL_ORDER_STATUS_LAST_WEEK = "/Order/getordersStatusLastWeek";
const URL_SALES_LAST_WEEK = "/Order/getsaleslastweek";

export const getOrderbyCompany = (id, startDate, endDate) => {
  return makeRequest(URL_GET_ORDER_COMPANY, id, startDate, endDate);
};

export const getOrderByStore = (id, startDate, endDate) => {
  return makeRequest(URL_GET_ORDER_STORE, id, startDate, endDate);
};

export const getOrderByProvider = (id, startDate, endDate, companyId) => {
  return makeRequest(URL_GET_ORDER_PROVIDER, id, startDate, endDate);
};

export const getOrderByEmployee = (id, startDate, endDate) => {
  return makeRequest(URL_GET_ORDER_EMPLOYEE, id, startDate, endDate);
};

export const saveOrderService = (body) => {
  return request(URL_SAVE_ORDER, httpTypes.POST, body);
};

export const getTopProducts = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_TOP_PRODUCTS, httpTypes.GET, null, params);
};

export const getCostInventoryAndTotalSales = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_COST_INVENTORY_SALES, httpTypes.GET, null, params);
};

export const getOrderStatusLastWeek = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_ORDER_STATUS_LAST_WEEK, httpTypes.GET, null, params);
};

export const getSalesLastWeek = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_SALES_LAST_WEEK, httpTypes.GET, null, params);
};

const makeRequest = (url, id, startDate, endDate, companyId) => {
  const params = {
    companyId: companyId,
    requestId: id,
    startDate: startDate,
    endDate: endDate,
  };
  return request(url, httpTypes.GET, null, params);
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editUserData,
  getUsersList,
  saveUserData,
} from "app/services/userService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorUser: null,
  users: [],
  loadingUser: false,
  user: null,
  saveUser: null,
  modeUser: crudTypes.create,
};

export const getUsers = createAsyncThunk("users/get", async () => {
  const res = await getUsersList();
  return res.data.result;
});

export const saveUserInfo = createAsyncThunk("users/post", async (body) => {
  const res = await saveUserData(body);
  return res.data.result;
});

export const editUserInfo = createAsyncThunk("users/put", async (body) => {
  const res = await editUserData(body);
  return res.data.result;
});

const userSlice = createSlice({
  initialState,
  name: "users",
  reducers: {
    setUser(state, actions) {
      const { payload } = actions;
      state.user = payload;
    },
    resetUser(state, actions) {
      state.user = null;
    },
    resetUserSave(state, actions) {
      state.saveUser = null;
    },
    resetErrorUser(state, actions) {
      state.errorUSer = null;
    },
    setModeUser(state, actions) {
      const { payload } = actions;
      state.modeUser = payload;
    },
    resetUsers(state, actions) {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(getUsers.fulfilled, (state, actions) => {
        state.loadingUser = false;
        state.users = actions.payload;
      })
      .addCase(getUsers.rejected, (state, actions) => {
        state.loadingUser = false;
        state.users = [];
        state.errorUser = actions.error.message;
      })
      .addCase(saveUserInfo.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(saveUserInfo.fulfilled, (state, actions) => {
        state.loadingUser = false;
        state.saveUser = actions.payload;
      })
      .addCase(saveUserInfo.rejected, (state, actions) => {
        state.loadingUser = false;
        state.errorUser = actions.error.message;
      })
      .addCase(editUserInfo.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(editUserInfo.fulfilled, (state, actions) => {
        state.loadingUser = false;
        state.saveUser = actions.payload;
      })
      .addCase(editUserInfo.rejected, (state, actions) => {
        state.loadingUser = false;
        state.errorUser = actions.error.message;
      });
  },
});
export const {
  setUser,
  resetUser,
  resetUserSave,
  resetErrorUser,
  setModeUser,
  resetUsers,
} = userSlice.actions;
export const userReducer = userSlice.reducer;

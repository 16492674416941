import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_DEPARTMENTS_BY_COUNTRY = "/state/by-country";
const URL_GET_DEPARTMENTS = "/state/allstate";
const URL_SAVE_DEPARTMENT = "/state/states";
const URL_EDIT_DEPARTMENT = "/state/state";

export const getDepartmentsListByCountry = (countryId) => {
  const data = {
    RequestId: countryId,
  };
  return request(URL_GET_DEPARTMENTS_BY_COUNTRY, httpTypes.GET, null, data);
};

export const getDepartmentsList = () => {
  return request(URL_GET_DEPARTMENTS, httpTypes.GET);
};

export const saveDepartmentData = (body) => {
  return request(URL_SAVE_DEPARTMENT, httpTypes.POST, body);
};

export const editDepartmentData = (body) => {
  return request(URL_EDIT_DEPARTMENT, httpTypes.PUT, body);
};

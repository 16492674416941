import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editProfileData,
  getProfileList,
  saveProfileData,
} from "app/services/profileService";

const initialState = {
  errorProfile: null,
  profiles: [],
  profile: null,
  loadingProfile: false,
  profileSave: null,
};

export const getProfiles = createAsyncThunk("profile/get", async () => {
  const res = await getProfileList();
  return res.data.result;
});

export const saveProfileInfo = createAsyncThunk(
  "profile/post",
  async (body) => {
    const res = await saveProfileData(body);
    return res.data.result;
  },
);

export const editProfileInfo = createAsyncThunk("profile/put", async (body) => {
  const res = await editProfileData(body);
  return res.data.result;
});

const profileSlice = createSlice({
  initialState,
  name: "profiles",
  reducers: {
    setProfile(state, actions) {
      const { payload } = actions;
      state.profile = payload;
    },
    resetProfile(state, actions) {
      state.profile = null;
    },
    resetProfileSave(state, actions) {
      state.profileSave = null;
    },
    resetProfiles(state, actions) {
      state.profiles = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfiles.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(getProfiles.fulfilled, (state, actions) => {
        state.loadingProfile = false;
        state.profiles = actions.payload;
      })
      .addCase(getProfiles.rejected, (state, actions) => {
        state.loadingProfile = false;
        state.profiles = [];
        state.errorProfile = actions.error.message;
      })
      .addCase(saveProfileInfo.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(saveProfileInfo.fulfilled, (state, actions) => {
        state.loadingProfile = false;
        state.profileSave = actions.payload;
      })
      .addCase(saveProfileInfo.rejected, (state, actions) => {
        state.loadingProfile = false;
        state.errorProfile = actions.error.message;
      })
      .addCase(editProfileInfo.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(editProfileInfo.fulfilled, (state, actions) => {
        state.loadingProfile = false;
        state.profileSave = actions.payload;
      })
      .addCase(editProfileInfo.rejected, (state, actions) => {
        state.loadingProfile = false;
        state.errorProfile = actions.error.message;
      });
  },
});
export const { setProfile, resetProfile, resetProfileSave, resetProfiles } =
  profileSlice.actions;
export const profileReducer = profileSlice.reducer;

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_USER = "/user/getallusers";
const URL_SAVE_USER = "/user/user";
const URL_EDIT_USER = "/user";

export const getUsersList = () => {
  return request(URL_GET_USER, httpTypes.GET);
};

export const saveUserData = (body) => {
  return request(URL_SAVE_USER, httpTypes.POST, body);
};

export const editUserData = (body) => {
  return request(URL_EDIT_USER, httpTypes.PUT, body);
};

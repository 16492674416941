import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_SAVE_REMISSION = "/remision/remision";
const RUL_GET_REMISSIONS = "/remision/allremisions";
const URL_GET_REMISSIONS_ORIGIN_DESTINY = "/remision/by-originAndDestiny";
const URL_GET_REMISSION_BY_ORIGIN = "/remision/by-storeOrigen";
const URL_GET_REMISSION_BY_DESTINY = "/remision/by-storeDestiny";
const URL_GET_REMISSION_BY_ITEM = "/remision/by-item";
const URL_APPROVE_REMISSION = "/remision/apprroveremision";
const URL_UPDATE_STATUS_REMISSION = "/remision/updatestatusremision";

export const getAllRemissionsByCompany = (id, startDate, endDate) => {
  return makeRequest(RUL_GET_REMISSIONS, id, startDate, endDate);
};

export const getRemissionsOriginDestiny = (filter) => {
  return request(
    URL_GET_REMISSIONS_ORIGIN_DESTINY,
    httpTypes.GET,
    null,
    filter
  );
};

export const getRemissionsOrigin = (id, startDate, endDate) => {
  return makeRequest(URL_GET_REMISSION_BY_ORIGIN, id, startDate, endDate);
};

export const getRemissionsDestiny = (id, startDate, endDate) => {
  return makeRequest(URL_GET_REMISSION_BY_DESTINY, id, startDate, endDate);
};

export const getRemissionsItem = (id, startDate, endDate) => {
  return makeRequest(URL_GET_REMISSION_BY_ITEM, id, startDate, endDate);
};

export const saveRemissionData = (body) => {
  return request(URL_SAVE_REMISSION, httpTypes.POST, body);
};

export const editRemissionData = (body) => {
  return request(URL_SAVE_REMISSION, httpTypes.PUT, body);
};

export const approveRemissionData = (body) => {
  return request(URL_APPROVE_REMISSION, httpTypes.PUT, body);
};

export const updateStatusRemissionData = (body) => {
  return request(URL_UPDATE_STATUS_REMISSION, httpTypes.PUT, body);
};

const makeRequest = (url, id, startDate, endDate) => {
  const params = {
    requestId: id,
    startDate: startDate,
    endDate: endDate,
  };
  return request(url, httpTypes.GET, null, params);
};

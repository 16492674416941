import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_INVENTORY_SUMMARY = "/InventoryByStore/itemssummary";

export const getInventorySummary = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_INVENTORY_SUMMARY, httpTypes.GET, null, params);
};

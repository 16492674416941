import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_COMPANY = "/company/allcompanies";
const URL_SAVE_COMPANY = "/company/create";
const URL_EDIT_COMPANY = "/company/update";

export const getCompanyList = () => {
  return request(URL_GET_COMPANY, httpTypes.GET);
};

export const saveCompanyData = (body) => {
  return request(URL_SAVE_COMPANY, httpTypes.POST, body);
};

export const editCompanyData = (body) => {
  return request(URL_EDIT_COMPANY, httpTypes.PUT, body);
};

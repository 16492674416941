import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getOderClient,
  getOrder,
  getOrderbyCompany,
  getOrderByEmployee,
  getOrderByProvider,
  getOrderByStore,
  saveOrderService,
} from "app/services/orderService";
import {
  approveRemissionData,
  editRemissionData,
  getAllRemissionsByCompany,
  getRemissionsDestiny,
  getRemissionsItem,
  getRemissionsOrigin,
  getRemissionsOriginDestiny,
  saveRemissionData,
  updateStatusRemissionData,
} from "app/services/remissionService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorRemissions: null,
  remissions: [],
  loadingRemissions: false,
  remission: null,
  saveRemission: null,
  modeRemission: crudTypes.create,
  isSend: true,
  approveRemission: null,
  statusRemissionData: null,
};

export const getAllRemissionsByCompanyList = createAsyncThunk(
  "getAllRemissionsByCompany/get",
  async (dates) => {
    const id = window.localStorage.getItem("companyId");
    const res = await getAllRemissionsByCompany(
      id,
      dates.startDate,
      dates.endDate
    );
    return res.data.result;
  }
);

export const getRemissionsOriginDestinyList = createAsyncThunk(
  "getRemissionsOriginDestiny/get",
  async (filter) => {
    filter["companyId"] = window.localStorage.getItem("companyId");
    const res = await getRemissionsOriginDestiny(filter);
    return res.data.result;
  }
);

export const getRemissionsOriginList = createAsyncThunk(
  "getRemissionsOrigin/get",
  async ({ id, dates }) => {
    const res = await getRemissionsOrigin(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const getRemissionsDestinyList = createAsyncThunk(
  "getRemissionsDestiny/get",
  async ({ id, dates }) => {
    const res = await getRemissionsDestiny(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const getRemissionsItemList = createAsyncThunk(
  "getRemissionsItem/get",
  async ({ id, dates }) => {
    const res = await getRemissionsItem(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const saveRemission = createAsyncThunk(
  "remissionSave/post",
  async (body) => {
    const res = await saveRemissionData(body);
    return res.data.result;
  }
);

export const editRemission = createAsyncThunk(
  "editRemission/post",
  async (body) => {
    const res = await editRemissionData(body);
    return res.data.result;
  }
);

export const approveRemssion = createAsyncThunk(
  "approveRemssion/post",
  async (body) => {
    const res = await approveRemissionData(body);
    return res.data.result;
  }
);

export const updateStatusRemission = createAsyncThunk(
  "updateStatusRemission/post",
  async (body) => {
    const res = await updateStatusRemissionData(body);
    return res.data.result;
  }
);

const remissionSlice = createSlice({
  initialState,
  name: "remissions",
  reducers: {
    setRemission(state, actions) {
      const { payload } = actions;
      state.remission = payload;
    },
    resetRemission(state, actions) {
      state.remission = null;
    },
    resetRemissionSave(state, actions) {
      state.saveRemission = null;
    },
    resetErrorRemission(state, actions) {
      state.errorRemissions = null;
    },
    setModeRemission(state, actions) {
      const { payload } = actions;
      state.modeRemission = payload;
    },
    resetRemissions(state, actions) {
      state.remissions = [];
    },
    setIsSend(state, actions) {
      const { payload } = actions;
      state.isSend = payload;
    },
    resetIsSend(state, actions) {
      state.isSend = true;
    },
    resetApproveRemission(state, actions) {
      state.approveRemission = null;
    },
    resetStatusRemissionData(state, actions) {
      state.statusRemissionData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRemissionsByCompanyList.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(getAllRemissionsByCompanyList.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = actions.payload;
      })
      .addCase(getAllRemissionsByCompanyList.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.remission = [];
        state.errorRemissions = actions.error.message;
      })
      .addCase(getRemissionsOriginDestinyList.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(getRemissionsOriginDestinyList.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = actions.payload;
      })
      .addCase(getRemissionsOriginDestinyList.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = [];
        state.errorRemissions = actions.error.message;
      })
      .addCase(getRemissionsOriginList.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(getRemissionsOriginList.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = actions.payload;
      })
      .addCase(getRemissionsOriginList.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = [];
        state.errorRemissions = actions.error.message;
      })
      .addCase(getRemissionsDestinyList.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(getRemissionsDestinyList.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = actions.payload;
      })
      .addCase(getRemissionsDestinyList.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = [];
        state.errorRemissions = actions.error.message;
      })
      .addCase(getRemissionsItemList.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(getRemissionsItemList.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = actions.payload;
      })
      .addCase(getRemissionsItemList.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.remissions = [];
        state.errorRemissions = actions.error.message;
      })
      .addCase(saveRemission.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(saveRemission.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.saveRemission = actions.payload;
      })
      .addCase(saveRemission.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.errorRemissions = actions.error.message;
      })
      .addCase(editRemission.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(editRemission.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.saveRemission = actions.payload;
      })
      .addCase(editRemission.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.errorRemissions = actions.error.message;
      })
      .addCase(approveRemssion.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(approveRemssion.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.approveRemission = actions.payload;
      })
      .addCase(approveRemssion.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.approveRemission = null;
        state.errorRemissions = actions.error.message;
      })
      .addCase(updateStatusRemission.pending, (state) => {
        state.loadingRemissions = true;
      })
      .addCase(updateStatusRemission.fulfilled, (state, actions) => {
        state.loadingRemissions = false;
        state.statusRemissionData = actions.payload;
      })
      .addCase(updateStatusRemission.rejected, (state, actions) => {
        state.loadingRemissions = false;
        state.statusRemissionData = null;
        state.errorRemissions = actions.error.message;
      });
  },
});
export const {
  setRemission,
  resetRemission,
  resetRemissionSave,
  resetErrorRemission,
  setModeRemission,
  resetRemissions,
  setIsSend,
  resetIsSend,
  resetApproveRemission,
  resetStatusRemissionData,
} = remissionSlice.actions;
export const remissionReducer = remissionSlice.reducer;

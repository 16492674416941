import clientPaths from "./views/pages/client/clientPaths";
import companyPath from "./views/pages/company/companyPath";
import employeePath from "./views/pages/employee/employeePath";
import itemPaths from "./views/pages/items/itemPath";
import orderPath from "./views/pages/orders/orderPath";
import parameterPath from "./views/pages/parameters/parameterPaths";
import providerPath from "./views/pages/provider/providerPath";
import remissionPaths from "./views/pages/remisions/remisionPaths";
import storePath from "./views/pages/store/storePath";
import userPath from "./views/pages/users/userPath";

const providerPaths = providerPath();
const parameterPaths = parameterPath();
const companyPaths = companyPath();
const employeePaths = employeePath();
const userPaths = userPath();

export const navigations = [
  { label: "páginas", type: "label" },
  {
    name: "Reportes gráficos",
    path: "/dashboard/inventory-management",
    icon: "show_chart",
  },

  {
    name: "Parametrizaciones",
    icon: "airplay",
    children: [
      { name: "Paises", path: parameterPaths.country, iconText: "F1" },
      {
        name: "Departamentos",
        path: parameterPaths.department,
        iconText: "F2",
      },
      { name: "Ciudades", path: parameterPaths.city, iconText: "F1" },
      { name: "Bancos", path: parameterPaths.bank, iconText: "F1" },
      { name: "Categorías", path: parameterPaths.category, iconText: "F2" },
      {
        name: "Tipos de documento",
        path: parameterPaths.typeDocument,
        iconText: "F2",
      },
      {
        name: "Perfiles",
        path: parameterPaths.profile,
        iconText: "F2",
      },
      {
        name: "Especificaciones",
        path: parameterPaths.specification,
        iconText: "F2",
      },
    ],
  },
  {
    name: "Proveedores",
    icon: "assignment_outline",
    children: [
      {
        name: "Lista de proveedores",
        path: providerPaths.listProvider,
        iconText: "F1",
      },
      {
        name: "Nuevo proveedor",
        path: providerPaths.formProvider,
        iconText: "F1",
      },
    ],
  },

  {
    name: "Compañias",
    icon: "business_outline",
    children: [
      {
        name: "Lista de compañias",
        path: companyPaths.listCompany,
        iconText: "F1",
      },
      {
        name: "Nueva compañia",
        path: companyPaths.formCompany,
        iconText: "F1",
      },
    ],
  },
  {
    name: "Tiendas",
    icon: "store",
    children: [
      {
        name: "Lista de tiendas",
        path: storePath.listStore,
        iconText: "F1",
      },
      {
        name: "Nueva tienda",
        path: storePath.formStore,
        iconText: "F1",
      },
    ],
  },
  {
    name: "Empleados",
    icon: "assignment_ind",
    children: [
      {
        name: "Lista de empleados",
        path: employeePaths.listEmployee,
        iconText: "F1",
      },
      {
        name: "Nuevo empleado",
        path: employeePaths.formEmployee,
        iconText: "F1",
      },
    ],
  },
  {
    name: "Usuarios",
    icon: "people",
    children: [
      {
        name: "Lista de usuarios",
        path: userPaths.listUser,
        iconText: "CL",
      },
      { name: "Nuevo usuario", path: userPaths.formUser, iconText: "NC" },
    ],
  },
  {
    name: "Productos",
    icon: "inventory_2",
    children: [
      {
        name: "Lista de productos",
        path: itemPaths.listItem,
        iconText: "CL",
      },
      { name: "Nuevo productos", path: itemPaths.formItem, iconText: "NC" },
    ],
  },
  {
    name: "Ordenes",
    icon: "grading",
    children: [
      {
        name: "Lista de ordenes",
        path: orderPath.listOrder,
        iconText: "CL",
      },
      {
        name: "Nueva orden",
        path: orderPath.createOrder,
        iconText: "CL",
      },
    ],
  },
  {
    name: "Clientes",
    icon: "accessibility",
    children: [
      {
        name: "Lista de clientes",
        path: clientPaths.listClient,
        iconText: "CL",
      },
      {
        name: "Nuevo cliente",
        path: clientPaths.formClient,
        iconText: "CL",
      },
    ],
  },
  {
    name: "Remisiones",
    icon: "import_export",
    children: [
      {
        name: "Lista de remisiones",
        path: remissionPaths.listRemissions,
        iconText: "CL",
      },
      {
        name: "Nuevo remision",
        path: remissionPaths.formRemissions,
        iconText: "CL",
      },
    ],
  },
];

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_STORE = "/store/By-StoreBycompany";
const URL_SAVE_STORE = "/store/create";
const URL_EDIT_STORE = "/store/update";
const URL_GET_STORE_BY_UBICATION = "/Store/By-storelocation";

export const getStoreList = (id) => {
  const paramRequest = {
    RequestId: id,
  };
  return request(URL_GET_STORE, httpTypes.GET, null, paramRequest);
};

export const getStoreByUbication = (params) => {
  return request(URL_GET_STORE_BY_UBICATION, httpTypes.GET, null, params);
};

export const saveStoreData = (body) => {
  return request(URL_SAVE_STORE, httpTypes.POST, body);
};

export const editStoreData = (body) => {
  return request(URL_EDIT_STORE, httpTypes.PUT, body);
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInventorySummary } from "app/services/inventory";

const initialState = {
  errorInventory: null,
  loadingInventory: false,
  inventorySummary: [],
};

export const getInventorySummaryList = createAsyncThunk(
  "getInventorySummaryList/get",
  async () => {
    const id = window.localStorage.getItem("companyId");
    const res = await getInventorySummary(id);
    return res.data.result;
  }
);

const inventorySlice = createSlice({
  initialState,
  name: "inventory",
  reducers: {
    resetInventorySummary(state, actions) {
      state.topProducts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventorySummaryList.pending, (state) => {
        state.loadingInventory = true;
      })
      .addCase(getInventorySummaryList.fulfilled, (state, actions) => {
        state.loadingInventory = false;
        state.inventorySummary = actions.payload;
      })
      .addCase(getInventorySummaryList.rejected, (state, actions) => {
        state.loadingInventory = false;
        state.inventorySummary = [];
        state.errorInventory = actions.error.message;
      });
  },
});
export const { resetInventorySummary } = inventorySlice.actions;
export const inventoryReducer = inventorySlice.reducer;

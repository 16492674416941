import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_PERSON_BY_FILTER = "/person/personbyfilter";
const URL_GET_PERSON_BY_ID = "/person/personbyid";
const URL_SAVE_PERSON = "/person/person";
const URL_EDIT_PERSON = "/person";
const URL_GET_CLIENTS_BY_COMPANY = "/person/clientsbycompany";

export const getPersonByFilterData = (filter) => {
  return request(URL_GET_PERSON_BY_FILTER, httpTypes.GET, null, filter);
};

export const getPersonByIdData = (filter) => {
  return request(URL_GET_PERSON_BY_ID, httpTypes.GET, null, filter);
};

export const savePersonData = (body) => {
  return request(URL_SAVE_PERSON, httpTypes.POST, body);
};

export const editPersonData = (body) => {
  return request(URL_EDIT_PERSON, httpTypes.PUT, body);
};

export const getClientsByCompanyData = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_CLIENTS_BY_COMPANY, httpTypes.GET, null, params);
};

import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Sales = Loadable(lazy(() => import("./Sales")));
const Analytics1 = Loadable(lazy(() => import("./Analytics1")));
const Analytics2 = Loadable(lazy(() => import("./Analytics2")));
const LearningManagement = Loadable(lazy(() => import("./LearningManagement")));
const InventoryManagement = Loadable(
  lazy(() => import("./InventoryManagement")),
);

const dashboardRoutes = [
  { path: "dashboard/sales", element: <Sales /> },
  { path: "dashboard/analytics-1", element: <Analytics1 /> },
  { path: "dashboard/analytics-2", element: <Analytics2 /> },
  { path: "dashboard/learning-management", element: <LearningManagement /> },
  { path: "dashboard/inventory-management", element: <InventoryManagement /> },
];

export default dashboardRoutes;

const parameterPath = () => {
  return {
    bank: "/pages/bank",
    category: "/pages/category",
    typeDocument: "/pages/typeDocument",
    city: "/pages/city",
    department: "/pages/department",
    country: "/pages/country",
    profile: "/pages/profile",
    specification: "pages/specification",
  };
};

export default parameterPath;

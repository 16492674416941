import { Box } from "@mui/material";

const SidenavContainer = ({ children }) => {
  return (
    <Box sx={{ height: "100%", display: "flex", position: "relative" }}>
      {children}
    </Box>
  );
};

export default SidenavContainer;

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_PROVIDERS = "/provider/providersbycompany";
const URL_SAVE_PROVIDER = "/provider/create";
const URL_EDIT_PROVIDER = "/provider/update";

export const getProviderList = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_PROVIDERS, httpTypes.GET, null, params);
};

export const saveProviderData = (body) => {
  return request(URL_SAVE_PROVIDER, httpTypes.POST, body);
};

export const editProviderData = (body) => {
  return request(URL_EDIT_PROVIDER, httpTypes.PUT, body);
};

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_TYPE_SPECIFICATION =
  "/TypeSpecificationProduct/alltypeSpecifications";
const URL_SAVE_TYPE_SPECIFICATION = "/TypeSpecificationProduct";

export const getTypeSpecificationList = () => {
  return request(URL_GET_TYPE_SPECIFICATION, httpTypes.GET);
};

export const saveTypeSpecificationData = (body) => {
  return request(URL_SAVE_TYPE_SPECIFICATION, httpTypes.POST, body);
};

export const editTypeSpecificationData = (body) => {
  return request(URL_SAVE_TYPE_SPECIFICATION, httpTypes.PUT, body);
};

import Loadable from "app/components/Loadable";
import { lazy } from "react";
import parameterPath from "./parameters/parameterPaths";
import providerPath from "./provider/providerPath";
import companyPath from "./company/companyPath";
import employeePath from "./employee/employeePath";
import userPath from "./users/userPath";
import itemPaths from "./items/itemPath";
import storePath from "./store/storePath";
import orderPath from "./orders/orderPath";
import clientPaths from "./client/clientPaths";
import remissionPaths from "./remisions/remisionPaths";

const Faq1 = Loadable(lazy(() => import("./faq/Faq1")));
const Faq2 = Loadable(lazy(() => import("./faq/Faq2")));
const OrderList = Loadable(lazy(() => import("./orders/OrderList")));
const OrderDetail = Loadable(lazy(() => import("./orders/OrderDetail")));
const OrderResume = Loadable(lazy(() => import("./orders/OrderResume")));
const UserList1 = Loadable(lazy(() => import("./user-list/UserList1")));
const UserList2 = Loadable(lazy(() => import("./user-list/UserList2")));
const UserList3 = Loadable(lazy(() => import("./user-list/UserList3")));
const UserList4 = Loadable(lazy(() => import("./user-list/UserList4")));
const ProductForm = Loadable(lazy(() => import("./products/ProductForm")));
const CustomerList = Loadable(lazy(() => import("./customers/CustomerList")));
const ProductViewer = Loadable(lazy(() => import("./products/ProductViewer")));
const CustomerForm = Loadable(
  lazy(() => import("./customers/customer-form/CustomerForm")),
);
const CustomerViewer = Loadable(
  lazy(() => import("./customers/customer-viewer/CustomerViewer")),
);
const CountryList = Loadable(
  lazy(() => import("./parameters/country/CountryList")),
);
const DepartmentList = Loadable(
  lazy(() => import("./parameters/department/DepartmentList")),
);
const CityList = Loadable(lazy(() => import("./parameters/city/CityList")));
const BankList = Loadable(lazy(() => import("./parameters/banks/BankList")));
const CategoryList = Loadable(
  lazy(() => import("./parameters/categories/CategoryList")),
);
const TypeDocumentList = Loadable(
  lazy(() => import("./parameters/typeDocuments/TypeDocumentList")),
);
const ProfileList = Loadable(
  lazy(() => import("./parameters/profile/ProfileList")),
);
const SpecificationList = Loadable(
  lazy(() => import("./parameters/specifications/SpecificationList")),
);
const ProviderList = Loadable(lazy(() => import("./provider/ProviderList")));
const ProviderResume = Loadable(
  lazy(() => import("./provider/ProviderResume")),
);
const CompanyList = Loadable(lazy(() => import("./company/CompanyList")));
const CompanyResume = Loadable(lazy(() => import("./company/CompanyResume")));
const EmployeeList = Loadable(lazy(() => import("./employee/EmployeeList")));
const EmployeeResume = Loadable(
  lazy(() => import("./employee/EmployeeResume")),
);
const StoreList = Loadable(lazy(() => import("./store/StoreList")));
const StoreResume = Loadable(lazy(() => import("./store/StoreResume")));
const UserList = Loadable(lazy(() => import("./users/UserList")));
const UserResume = Loadable(lazy(() => import("./users/UserResume")));
const ProductList = Loadable(lazy(() => import("./items/ItemList")));
const ProductResume = Loadable(lazy(() => import("./items/ItemResume")));
const ClientList = Loadable(lazy(() => import("./client/ClientList")));
const ClientResume = Loadable(lazy(() => import("./client/ClientResume")));
const RemissionList = Loadable(lazy(() => import("./remisions/RemissionList")));
const RemissionResume = Loadable(lazy(() => import("./remisions/RemissionResume")));

const parameterPaths = parameterPath();
const providerPaths = providerPath();
const companyPaths = companyPath();
const employeePaths = employeePath();
const userPaths = userPath();
const pagesRoutes = [
  { path: "/pages/user-list-1", element: <UserList1 /> },
  { path: "/pages/user-list-2", element: <UserList2 /> },
  { path: "/pages/user-list-3", element: <UserList3 /> },
  { path: "/pages/user-list-4", element: <UserList4 /> },
  { path: "/pages/faq-1", element: <Faq1 /> },
  { path: "/pages/faq-2", element: <Faq2 /> },
  { path: "/pages/customer-list", element: <CustomerList /> },
  { path: "/pages/new-customer", element: <CustomerForm /> },
  { path: "/pages/view-customer", element: <CustomerViewer /> },
  { path: "/pages/product-list", element: <ProductList /> },
  { path: "/pages/new-product", element: <ProductForm /> },
  { path: "/pages/view-product", element: <ProductViewer /> },
  { path: orderPath.listOrder, element: <OrderList /> },
  { path: orderPath.detailOrder, element: <OrderDetail /> },
  { path: orderPath.createOrder, element: <OrderResume /> },
  { path: parameterPaths.country, element: <CountryList /> },
  { path: parameterPaths.department, element: <DepartmentList /> },
  { path: parameterPaths.city, element: <CityList /> },
  { path: parameterPaths.bank, element: <BankList /> },
  { path: parameterPaths.category, element: <CategoryList /> },
  { path: parameterPaths.typeDocument, element: <TypeDocumentList /> },
  { path: parameterPaths.profile, element: <ProfileList /> },
  { path: parameterPaths.specification, element: <SpecificationList /> },
  { path: providerPaths.listProvider, element: <ProviderList /> },
  { path: providerPaths.formProvider, element: <ProviderResume /> },
  { path: companyPaths.listCompany, element: <CompanyList /> },
  { path: companyPaths.formCompany, element: <CompanyResume /> },
  { path: employeePaths.listEmployee, element: <EmployeeList /> },
  { path: employeePaths.formEmployee, element: <EmployeeResume /> },
  { path: storePath.listStore, element: <StoreList /> },
  { path: storePath.formStore, element: <StoreResume /> },
  { path: userPaths.listUser, element: <UserList /> },
  { path: userPaths.formUser, element: <UserResume /> },
  { path: itemPaths.listItem, element: <ProductList /> },
  { path: itemPaths.formItem, element: <ProductResume /> },
  { path: clientPaths.listClient, element: <ClientList /> },
  { path: clientPaths.formClient, element: <ClientResume /> },
  { path: remissionPaths.listRemissions, element: <RemissionList /> },
  { path: remissionPaths.formRemissions, element: <RemissionResume /> },
];

export default pagesRoutes;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editPersonData,
  getClientsByCompanyData,
  getPersonByFilterData,
  getPersonByIdData,
  savePersonData,
} from "app/services/personService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorPerson: null,
  persons: [],
  loadingPerson: false,
  person: null,
  searchPerson: false,
  savePerson: null,
  modePerson: crudTypes.create,
};

export const getPersonByFilter = createAsyncThunk(
  "personByFilter/get",
  async (filter) => {
    const res = await getPersonByFilterData(filter);
    return res.data.result;
  },
);

export const getPersonById = createAsyncThunk(
  "personById/get",
  async (filter) => {
    const res = await getPersonByIdData(filter);
    return res.data.result;
  },
);

export const savePerson = createAsyncThunk("person/post", async (body) => {
  const res = await savePersonData(body);
  return res.data.result;
});

export const editPerson = createAsyncThunk("person/put", async (body) => {
  const res = await editPersonData(body);
  return res.data.result;
});

export const getClientsByCompany = createAsyncThunk(
  "getClientsByCompany/get",
  async () => {
    console.log('entro');
    const id = window.localStorage.getItem("companyId");
    const res = await getClientsByCompanyData(id);
    return res.data.result;
  },
);

const personSlice = createSlice({
  initialState,
  name: "person",
  reducers: {
    setPerson(state, actions) {
      const { payload } = actions;
      state.person = payload;
    },
    resetPerson(state, actions) {
      state.person = null;
    },
    resetSearchPerson(state, actions) {
      state.searchPerson = false;
    },
    resetPersonSave(state, actions) {
      state.savePerson = null;
    },
    setModePerson(state, actions) {
      const { payload } = actions;
      state.modePerson = payload;
    },
    resetPersons(state, actions) {
      state.persons = [];
    },
    resetErrorPerson(state, actions) {
      state.errorPerson = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersonByFilter.pending, (state) => {
        state.loadingPerson = true;
      })
      .addCase(getPersonByFilter.fulfilled, (state, actions) => {
        state.loadingPerson = false;
        state.person = actions.payload[0];
        state.searchPerson = true;
      })
      .addCase(getPersonByFilter.rejected, (state, actions) => {
        state.loadingPerson = false;
        state.searchPerson = true;
        state.person = null;
        state.errorPerson = actions.error.message;
      })
      .addCase(getPersonById.pending, (state) => {
        state.loadingPerson = true;
      })
      .addCase(getPersonById.fulfilled, (state, actions) => {
        state.loadingPerson = false;
        state.person = actions.payload;
        state.searchPerson = true;
      })
      .addCase(getPersonById.rejected, (state, actions) => {
        state.loadingPerson = false;
        state.searchPerson = true;
        state.person = null;
        state.errorPerson = actions.error.message;
      })
      .addCase(savePerson.pending, (state) => {
        state.loadingPerson = true;
      })
      .addCase(savePerson.fulfilled, (state, actions) => {
        state.loadingPerson = false;
        state.person = actions.payload;
      })
      .addCase(savePerson.rejected, (state, actions) => {
        state.loadingPerson = false;
        state.errorPerson = actions.error.message;
      })
      .addCase(editPerson.pending, (state) => {
        state.loadingPerson = true;
      })
      .addCase(editPerson.fulfilled, (state, actions) => {
        state.loadingPerson = false;
        state.savePerson = actions.payload;
      })
      .addCase(editPerson.rejected, (state, actions) => {
        state.loadingPerson = false;
        state.errorPerson = actions.error.message;
      })
      .addCase(getClientsByCompany.pending, (state) => {
        state.loadingPerson = true;
      })
      .addCase(getClientsByCompany.fulfilled, (state, actions) => {
        state.loadingPerson = false;
        state.persons = actions.payload;
      })
      .addCase(getClientsByCompany.rejected, (state, actions) => {
        state.loadingPerson = false;
        state.persons = [];
        state.errorPerson = actions.error.message;
      });
  },
});

export const {
  setPerson,
  resetPerson,
  resetPersonSave,
  resetSearchPerson,
  setModePerson,
  resetPersons,
  resetErrorPerson,
} = personSlice.actions;
export const personReducer = personSlice.reducer;

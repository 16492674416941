import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editBankData,
  getBankList,
  saveBankData,
} from "app/services/bankService";

const initialState = {
  error: null,
  banks: [],
  bank: null,
  loadingBank: false,
  bankSave: null,
};

export const getBanks = createAsyncThunk("banks/get", async () => {
  const res = await getBankList();
  return res.data.result;
});

export const saveBank = createAsyncThunk("banks/post", async (body) => {
  const res = await saveBankData(body);
  return res.data.result;
});

export const editBank = createAsyncThunk("banks/put", async (body) => {
  const res = await editBankData(body);
  return res.data.result;
});

const bankSlice = createSlice({
  initialState,
  name: "banks",
  reducers: {
    setBank(state, actions) {
      const { payload } = actions;
      state.bank = payload;
    },
    resetBank(state, actions) {
      state.bank = null;
    },
    resetBankSave(state, actions) {
      state.bankSave = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.pending, (state) => {
        state.loadingBank = true;
      })
      .addCase(getBanks.fulfilled, (state, actions) => {
        state.loadingBank = false;
        state.banks = actions.payload;
      })
      .addCase(getBanks.rejected, (state, actions) => {
        state.loadingBank = false;
        state.banks = [];
        state.error = actions.error.message;
      })
      .addCase(saveBank.pending, (state) => {
        state.loadingBank = true;
      })
      .addCase(saveBank.fulfilled, (state, actions) => {
        state.loadingBank = false;
        state.bankSave = actions.payload;
      })
      .addCase(saveBank.rejected, (state, actions) => {
        state.loadingBank = false;
        state.error = actions.error.message;
      })
      .addCase(editBank.pending, (state) => {
        state.loadingBank = true;
      })
      .addCase(editBank.fulfilled, (state, actions) => {
        state.loadingBank = false;
        state.bankSave = actions.payload;
      })
      .addCase(editBank.rejected, (state, actions) => {
        state.loadingBank = false;
        state.error = actions.error.message;
      });
  },
});
export const { setBank, resetBank, resetBankSave } = bankSlice.actions;
export const bankReducer = bankSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCostInventoryAndTotalSales,
  getOderClient,
  getOrder,
  getOrderbyCompany,
  getOrderByEmployee,
  getOrderByProvider,
  getOrderByStore,
  getOrderStatusLastWeek,
  getSalesLastWeek,
  getTopProducts,
  saveOrderService,
} from "app/services/orderService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorOrder: null,
  orders: [],
  loadingOrder: false,
  order: null,
  saveOrder: null,
  modeOrder: crudTypes.create,
  topProducts: [],
  inventorySales: null,
  stautsSales: [],
  salesLastWeek: [],
};

export const getOrderbyCompanyList = createAsyncThunk(
  "getOrderbyCompany/get",
  async (dates) => {
    const id = window.localStorage.getItem("companyId");
    const res = await getOrderbyCompany(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const getOrderByStoreList = createAsyncThunk(
  "getOrderByStore/get",
  async ({ id, dates }) => {
    const res = await getOrderByStore(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const getOrderbyProviderList = createAsyncThunk(
  "getOrderByProvider/get",
  async ({ id, dates }) => {
    const companyId = window.localStorage.getItem("companyId");
    const res = await getOrderByProvider(
      id,
      dates.startDate,
      dates.endDate,
      companyId
    );
    return res.data.result;
  }
);

export const orderSave = createAsyncThunk("saveOrder/post", async (body) => {
  const res = await saveOrderService(body);
  return res.data.result;
});

export const getOrderByEmployeeList = createAsyncThunk(
  "getOrderByEmployee/get",
  async ({ id, dates }) => {
    const res = await getOrderByEmployee(id, dates.startDate, dates.endDate);
    return res.data.result;
  }
);

export const getTopProductsList = createAsyncThunk(
  "getTopProductsList/get",
  async () => {
    const id = window.localStorage.getItem("companyId");
    const res = await getTopProducts(id);
    return res.data.result;
  }
);

export const getCostInventoryAndTotalSalesData = createAsyncThunk(
  "getCostInventoryAndTotalSalesData/get",
  async () => {
    const id = window.localStorage.getItem("companyId");
    const res = await getCostInventoryAndTotalSales(id);
    return res.data.result;
  }
);

export const getOrderStatusLastWeekData = createAsyncThunk(
  "getOrderStatusLastWeek/get",
  async () => {
    const id = window.localStorage.getItem("companyId");
    const res = await getOrderStatusLastWeek(id);
    return res.data.result;
  }
);

export const getSalesLastWeekData = createAsyncThunk(
  "getSalesLastWeekData/get",
  async () => {
    const id = window.localStorage.getItem("companyId");
    const res = await getSalesLastWeek(id);
    return res.data.result;
  }
);

const orderSlice = createSlice({
  initialState,
  name: "orders",
  reducers: {
    setOrder(state, actions) {
      const { payload } = actions;
      state.order = payload;
    },
    resetOrder(state, actions) {
      state.order = null;
    },
    resetOrderSave(state, actions) {
      state.saveOrder = null;
    },
    resetErrorOrder(state, actions) {
      state.errorOrder = null;
    },
    setModeOrder(state, actions) {
      const { payload } = actions;
      state.modeOrder = payload;
    },
    resetOrders(state, actions) {
      state.orders = [];
    },
    resetTopProducts(state, actions) {
      state.topProducts = [];
    },
    resetInventorySales(state, actions) {
      state.inventorySales = null;
    },
    resetStatusSales(state, actions) {
      state.stautsSales = [];
    },
    resetSalesLastWeek(state, actions) {
      state.salesLastWeek = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderbyCompanyList.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getOrderbyCompanyList.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.orders = actions.payload;
      })
      .addCase(getOrderbyCompanyList.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.orders = [];
        state.errorOrder = actions.error.message;
      })
      .addCase(getOrderByStoreList.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getOrderByStoreList.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.orders = actions.payload;
      })
      .addCase(getOrderByStoreList.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.orders = [];
        state.errorOrder = actions.error.message;
      })
      .addCase(getOrderbyProviderList.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getOrderbyProviderList.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.orders = actions.payload;
      })
      .addCase(getOrderbyProviderList.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.orders = [];
        state.errorOrder = actions.error.message;
      })
      .addCase(getOrderByEmployeeList.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getOrderByEmployeeList.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.orders = actions.payload;
      })
      .addCase(getOrderByEmployeeList.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.orders = [];
        state.errorOrder = actions.error.message;
      })
      .addCase(orderSave.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(orderSave.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.saveOrder = actions.payload;
      })
      .addCase(orderSave.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.errorOrder = actions.error.message;
      })
      .addCase(getTopProductsList.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getTopProductsList.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.topProducts = actions.payload;
      })
      .addCase(getTopProductsList.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.errorOrder = actions.error.message;
      })
      .addCase(getCostInventoryAndTotalSalesData.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(
        getCostInventoryAndTotalSalesData.fulfilled,
        (state, actions) => {
          state.loadingOrder = false;
          state.inventorySales = actions.payload;
        }
      )
      .addCase(getCostInventoryAndTotalSalesData.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.errorOrder = actions.error.message;
        state.inventorySales = null;
      })
      .addCase(getOrderStatusLastWeekData.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getOrderStatusLastWeekData.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.stautsSales = actions.payload;
      })
      .addCase(getOrderStatusLastWeekData.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.errorOrder = actions.error.message;
        state.stautsSales = [];
      })
      .addCase(getSalesLastWeekData.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(getSalesLastWeekData.fulfilled, (state, actions) => {
        state.loadingOrder = false;
        state.salesLastWeek = actions.payload;
      })
      .addCase(getSalesLastWeekData.rejected, (state, actions) => {
        state.loadingOrder = false;
        state.errorOrder = actions.error.message;
        state.salesLastWeek = [];
      });
  },
});
export const {
  setOrder,
  resetOrder,
  resetOrderSave,
  resetErrorOrder,
  setModeOrder,
  resetOrders,
  resetTopProducts,
  resetInventorySales,
  resetStatusSales,
  resetSalesLastWeek
} = orderSlice.actions;
export const orderReducer = orderSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editTypeSpecificationData,
  getTypeSpecificationList,
  saveTypeSpecificationData,
} from "app/services/typeSpecificationService";

const initialState = {
  errorTypeSpecifications: null,
  typeSpecifications: [],
  typeSpecification: null,
  loadingTypeSpefication: false,
  typeSpecificationSave: null,
};

export const getTypeSpecifications = createAsyncThunk(
  "typeSpecifications/get",
  async () => {
    const res = await getTypeSpecificationList();
    return res.data.result;
  },
);

export const saveTypeSpecification = createAsyncThunk(
  "typeSpecifications/post",
  async (body) => {
    const res = await saveTypeSpecificationData(body);
    return res.data.result;
  },
);

export const editTypeSpecification = createAsyncThunk(
  "typeSpecifications/put",
  async (body) => {
    const res = await editTypeSpecificationData(body);
    return res.data.result;
  },
);

const typeSpecificationSlice = createSlice({
  initialState,
  name: "typeSpecification",
  reducers: {
    setTypeSpecification(state, actions) {
      const { payload } = actions;
      state.typeSpecification = payload;
    },
    resetTypeSpecification(state, actions) {
      state.typeSpecification = null;
    },
    resetTypeSpecificationSave(state, actions) {
      state.typeSpecificationSave = null;
    },
    resetTypeSpecifications(state, actions) {
      state.typeSpecifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypeSpecifications.pending, (state) => {
        state.loadingTypeSpefication = true;
      })
      .addCase(getTypeSpecifications.fulfilled, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.typeSpecifications = actions.payload;
      })
      .addCase(getTypeSpecifications.rejected, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.typeSpecifications = [];
        state.errorTypeSpecifications = actions.error.message;
      })
      .addCase(saveTypeSpecification.pending, (state) => {
        state.loadingTypeSpefication = true;
      })
      .addCase(saveTypeSpecification.fulfilled, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.typeSpecificationSave = actions.payload;
      })
      .addCase(saveTypeSpecification.rejected, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.errorTypeSpecifications = actions.error.message;
      })
      .addCase(editTypeSpecification.pending, (state) => {
        state.loadingTypeSpefication = true;
      })
      .addCase(editTypeSpecification.fulfilled, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.typeSpecificationSave = actions.payload;
      })
      .addCase(editTypeSpecification.rejected, (state, actions) => {
        state.loadingTypeSpefication = false;
        state.errorTypeSpecifications = actions.error.message;
      });
  },
});
export const {
  setTypeSpecification,
  resetTypeSpecification,
  resetTypeSpecificationSave,
  resetTypeSpecifications,
} = typeSpecificationSlice.actions;
export const typeSpecificationReducer = typeSpecificationSlice.reducer;

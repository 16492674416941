import { request } from "app/utils/http-client";

const URL_LOGIN = "/Auth/login";

export const loginUser = (data) => {
  const body = {
    userName: data.user,
    password: data.password,
  };
  return request(URL_LOGIN, "post", body);
};

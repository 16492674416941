import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editProviderData,
  getProviderList,
  saveProviderData,
} from "app/services/providerService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  error: null,
  providers: [],
  loadingProvider: false,
  provider: null,
  saveProvider: null,
  modeProvider: crudTypes.create,
};

export const getProviders = createAsyncThunk("providers/get", async () => {
  const id = window.localStorage.getItem("companyId");
  const res = await getProviderList(id);
  return res.data.result;
});

export const saveProviderInfo = createAsyncThunk(
  "providers/post",
  async (body) => {
    const res = await saveProviderData(body);
    return res.data.result;
  },
);

export const editProvider = createAsyncThunk("providers/put", async (body) => {
  const res = await editProviderData(body);
  return res.data.result;
});

const providersSlice = createSlice({
  initialState,
  name: "provider",
  reducers: {
    setProvider(state, actions) {
      const { payload } = actions;
      state.provider = payload;
    },
    resetProvider(state, actions) {
      state.provider = null;
    },
    resetProviderSave(state, actions) {
      state.saveProvider = null;
    },
    resetError(state, actions) {
      state.error = null;
    },
    setModeProvider(state, actions) {
      const { payload } = actions;
      state.modeProvider = payload;
    },
    resetProviders(state, actions) {
      state.providers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviders.pending, (state) => {
        state.loadingProvider = true;
      })
      .addCase(getProviders.fulfilled, (state, actions) => {
        state.loadingProvider = false;
        state.providers = actions.payload;
      })
      .addCase(getProviders.rejected, (state, actions) => {
        state.loadingProvider = false;
        state.providers = [];
        state.error = actions.error.message;
      })
      .addCase(saveProviderInfo.pending, (state) => {
        state.loadingProvider = true;
      })
      .addCase(saveProviderInfo.fulfilled, (state, actions) => {
        state.loadingProvider = false;
        state.saveProvider = actions.payload;
      })
      .addCase(saveProviderInfo.rejected, (state, actions) => {
        state.loadingProvider = false;
        state.error = actions.error.message;
      })
      .addCase(editProvider.pending, (state) => {
        state.loadingProvider = true;
      })
      .addCase(editProvider.fulfilled, (state, actions) => {
        state.loadingProvider = false;
        state.saveProvider = actions.payload;
      })
      .addCase(editProvider.rejected, (state, actions) => {
        state.loadingProvider = false;
        state.error = actions.error.message;
      });
  },
});

export const {
  setProvider,
  resetProvider,
  resetProviderSave,
  setModeProvider,
  resetError,
  resetProviders,
} = providersSlice.actions;
export const providersReducer = providersSlice.reducer;

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_CITIES_BY_STATE = "/city/by-state";
const URL_GET_CITIES = "/city/allcities";
const URL_SAVE_CITY = "/city/create";
const URL_EDIT_CITY = "/city/update";

export const getCityListByState = (stateId) => {
  const data = {
    RequestId: stateId,
  };
  return request(URL_GET_CITIES_BY_STATE, httpTypes.GET, null, data);
};

export const getCityList = () => {
  return request(URL_GET_CITIES, httpTypes.GET);
};

export const saveCityData = (body) => {
  return request(URL_SAVE_CITY, httpTypes.POST, body);
};

export const editCityData = (body) => {
  return request(URL_EDIT_CITY, httpTypes.PUT, body);
};

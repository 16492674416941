import { configureStore } from "@reduxjs/toolkit";
import { brandReducer } from "./slices/brandSlice";
import { cartReducer } from "./slices/cartSlice";
import { categoryReducer } from "./slices/categorySlice";
import { notificationReducer } from "./slices/notificationSlice";
import { productReducer } from "./slices/productSlice";
import { ratingReducer } from "./slices/ratingSlice";
import { scrumReducer } from "./slices/scrumSlice";
import { userReducer } from "./slices/user/userSlice";
import { countryReducer } from "./slices/countries/countryListSlice";
import { departmentsReducer } from "./slices/departments/departmentListSlice";
import { citiesReducer } from "./slices/cities/cityListSlice";
import { bankReducer } from "./slices/banks/bankListSlice";
import { categoryListReducer } from "./slices/categories/categoryListSlice";
import { typeDocumentReducer } from "./slices/typeDocuments/typeDocumentSlice";
import { providersReducer } from "./slices/providers/providersSlice";
import { personReducer } from "./slices/person/personsSlice";
import { companyReducer } from "./slices/company/companySlice";
import { employeeReducer } from "./slices/employees/employeeSlice";
import { profileReducer } from "./slices/profile/profileScile";
import { itemReducer } from "./slices/items/itemSlice";
import { typeSpecificationReducer } from "./slices/typeSpecifications/typeSpecificationsSlice";
import { storeReducer } from "./slices/store/storeSlice";
import { orderReducer } from "./slices/orders/orderSlice";
import { remissionReducer } from "./slices/remissions/remissionSlice";
import { inventoryReducer } from "./slices/inventory/inventorySlice";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    users: userReducer,
    brands: brandReducer,
    ratings: ratingReducer,
    scrumboard: scrumReducer,
    products: productReducer,
    categories: categoryReducer,
    notifications: notificationReducer,
    countries: countryReducer,
    departments: departmentsReducer,
    cities: citiesReducer,
    banks: bankReducer,
    categories: categoryListReducer,
    typeDocuments: typeDocumentReducer,
    providers: providersReducer,
    persons: personReducer,
    companies: companyReducer,
    employees: employeeReducer,
    profiles: profileReducer,
    store: storeReducer,
    items: itemReducer,
    typeSpecifications: typeSpecificationReducer,
    order: orderReducer,
    remission: remissionReducer,
    inventory: inventoryReducer
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

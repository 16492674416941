import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_EMPLOYEE = "/employee/employeebycompany";
const URL_SAVE_EMPLOYEE = "/employee/create";
const URL_EDIT_EMPLOYEE = "/employee/update";

export const getEmployeeList = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_EMPLOYEE, httpTypes.GET, null, params);
};

export const saveEmployeeData = (body) => {
  return request(URL_SAVE_EMPLOYEE, httpTypes.POST, body);
};

export const editEmployeeData = (body) => {
  return request(URL_EDIT_EMPLOYEE, httpTypes.PUT, body);
};

import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_ITEMS = "/Item/getItemDescription";
const URL_GET_ITEMS_COMPANY = "/Item/ItemsByCompanies";
const URL_SAVE_ITEM = "/item";
const URL_GET_ITEMS_STORE = "/inventoryByStore/inventorybystore";

export const getItemList = () => {
  return request(URL_GET_ITEMS, httpTypes.GET, null, { RequestName: "a" });
};

export const getItemByCompanyList = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_ITEMS_COMPANY, httpTypes.GET, null, params);
};

export const getItemsByStore = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_ITEMS_STORE, httpTypes.GET, null, params);
};

export const saveItemData = (body) => {
  return request(URL_SAVE_ITEM, httpTypes.POST, body);
};

export const editItemData = (body) => {
  return request(URL_SAVE_ITEM, httpTypes.PUT, body);
};

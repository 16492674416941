import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editCompanyData,
  getCompanyList,
  saveCompanyData,
} from "app/services/companyService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorCompany: null,
  companies: [],
  loadingCompany: false,
  company: null,
  saveCompany: null,
  modeCompany: crudTypes.create,
};

export const getCompanies = createAsyncThunk("company/get", async () => {
  const res = await getCompanyList();
  return res.data.result;
});

export const saveCompanyInfo = createAsyncThunk(
  "company/post",
  async (body) => {
    const res = await saveCompanyData(body);
    return res.data.result;
  },
);

export const editCompanyInfo = createAsyncThunk("company/put", async (body) => {
  const res = await editCompanyData(body);
  return res.data.result;
});

const companySlice = createSlice({
  initialState,
  name: "company",
  reducers: {
    setCompany(state, actions) {
      const { payload } = actions;
      state.company = payload;
    },
    resetCompany(state, actions) {
      state.company = null;
    },
    resetCompanySave(state, actions) {
      state.saveCompany = null;
    },
    resetErrorCompany(state, actions) {
      state.errorCompany = null;
    },
    setModeCompany(state, actions) {
      const { payload } = actions;
      state.modeCompany = payload;
    },
    resetCompanies(state, actions) {
      state.companies = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.loadingCompany = true;
      })
      .addCase(getCompanies.fulfilled, (state, actions) => {
        state.loadingCompany = false;
        state.companies = actions.payload;
      })
      .addCase(getCompanies.rejected, (state, actions) => {
        state.loadingCompany = false;
        state.companies = [];
        state.errorCompany = actions.error.message;
      })
      .addCase(saveCompanyInfo.pending, (state) => {
        state.loadingCompany = true;
      })
      .addCase(saveCompanyInfo.fulfilled, (state, actions) => {
        state.loadingCompany = false;
        state.saveCompany = actions.payload;
      })
      .addCase(saveCompanyInfo.rejected, (state, actions) => {
        state.loadingCompany = false;
        state.errorCompany = actions.error.message;
      })
      .addCase(editCompanyInfo.pending, (state) => {
        state.loadingCompany = true;
      })
      .addCase(editCompanyInfo.fulfilled, (state, actions) => {
        state.loadingCompany = false;
        state.saveCompany = actions.payload;
      })
      .addCase(editCompanyInfo.rejected, (state, actions) => {
        state.loadingCompany = false;
        state.errorCompany = actions.error.message;
      });
  },
});

export const {
  setCompany,
  resetCompany,
  resetCompanySave,
  resetErrorCompany,
  setModeCompany,
  resetCompanies,
} = companySlice.actions;
export const companyReducer = companySlice.reducer;

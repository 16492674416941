import { Box } from "@mui/material";

const SidenavContent = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1, height: "100%", position: "relative" }}>
      {children}
    </Box>
  );
};

export default SidenavContent;

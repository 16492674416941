import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editEmployeeData,
  getEmployeeList,
  saveEmployeeData,
} from "app/services/employeeService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorEmployee: null,
  employees: [],
  loadingEmployee: false,
  employee: null,
  saveEmployee: null,
  modeEmployee: crudTypes.create,
};

export const getEmployees = createAsyncThunk("employee/get", async () => {
  const id = window.localStorage.getItem("companyId");
  const res = await getEmployeeList(id);
  return res.data.result;
});

export const saveEmployeeInfo = createAsyncThunk(
  "employee/post",
  async (body) => {
    const res = await saveEmployeeData(body);
    return res.data.result;
  },
);

export const editEmployeeInfo = createAsyncThunk(
  "employee/put",
  async (body) => {
    const res = await editEmployeeData(body);
    return res.data.result;
  },
);

const employeeSlice = createSlice({
  initialState,
  name: "employee",
  reducers: {
    setEmployee(state, actions) {
      const { payload } = actions;
      state.employee = payload;
    },
    resetEmployee(state, actions) {
      state.employee = null;
    },
    resetEmployeeSave(state, actions) {
      state.saveCompany = null;
    },
    resetErrorEmployee(state, actions) {
      state.errorEmployee = null;
    },
    setModeEmployee(state, actions) {
      const { payload } = actions;
      state.modeEmployee = payload;
    },
    resetEmployees(state, actions) {
      state.employees = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.loadingEmployee = true;
      })
      .addCase(getEmployees.fulfilled, (state, actions) => {
        state.loadingEmployee = false;
        state.employees = actions.payload;
      })
      .addCase(getEmployees.rejected, (state, actions) => {
        state.loadingEmployee = false;
        state.employees = [];
        state.errorEmployee = actions.error.message;
      })
      .addCase(saveEmployeeInfo.pending, (state) => {
        state.loadingEmployee = true;
      })
      .addCase(saveEmployeeInfo.fulfilled, (state, actions) => {
        state.loadingEmployee = false;
        state.saveEmployee = actions.payload;
      })
      .addCase(saveEmployeeInfo.rejected, (state, actions) => {
        state.loadingEmployee = false;
        state.errorEmployee = actions.error.message;
      })
      .addCase(editEmployeeInfo.pending, (state) => {
        state.loadingEmployee = true;
      })
      .addCase(editEmployeeInfo.fulfilled, (state, actions) => {
        state.loadingEmployee = false;
        state.saveEmployee = actions.payload;
      })
      .addCase(editEmployeeInfo.rejected, (state, actions) => {
        state.loadingEmployee = false;
        state.errorEmployee = actions.error.message;
      });
  },
});

export const {
  setEmployee,
  resetEmployee,
  resetEmployeeSave,
  resetErrorEmployee,
  setModeEmployee,
  resetEmployees,
} = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;

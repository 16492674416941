import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editStoreData,
  getStoreByUbication,
  getStoreList,
  saveStoreData,
} from "app/services/storeService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorStore: null,
  stores: [],
  loadingStore: false,
  store: null,
  saveStore: null,
  modeStore: crudTypes.create,
};

export const getStore = createAsyncThunk("store/get", async () => {
  const id = window.localStorage.getItem("companyId");
  const res = await getStoreList(id);
  return res.data.result;
});

export const getStoreByUbicationData = createAsyncThunk(
  "storeUbication/get",
  async (params) => {
    const res = await getStoreByUbication(params);
    return res.data.result;
  }
);

export const saveStoreInfo = createAsyncThunk("store/post", async (body) => {
  const id = window.localStorage.getItem("companyId");
  body["companyId"] = id;
  const res = await saveStoreData(body);
  return res.data.result;
});

export const editStoreInfo = createAsyncThunk("store/put", async (body) => {
  const id = window.localStorage.getItem("companyId");
  body["companyId"] = id;
  const res = await editStoreData(body);
  return res.data.result;
});

const storeSlice = createSlice({
  initialState,
  name: "stores",
  reducers: {
    setStore(state, actions) {
      const { payload } = actions;
      state.store = payload;
    },
    resetStore(state, actions) {
      state.store = null;
    },
    resetStoreSave(state, actions) {
      state.saveStore = null;
    },
    resetErrorStore(state, actions) {
      state.errorStore = null;
    },
    setModeStore(state, actions) {
      const { payload } = actions;
      state.modeStore = payload;
    },
    resetStores(state, actions) {
      state.stores = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStore.pending, (state) => {
        state.loadingStore = true;
      })
      .addCase(getStore.fulfilled, (state, actions) => {
        state.loadingStore = false;
        state.stores = actions.payload;
      })
      .addCase(getStore.rejected, (state, actions) => {
        state.loadingStore = false;
        state.stores = [];
        state.errorStore = actions.error.message;
      })
      .addCase(saveStoreInfo.pending, (state) => {
        state.loadingStore = true;
      })
      .addCase(saveStoreInfo.fulfilled, (state, actions) => {
        state.loadingStore = false;
        state.saveStore = actions.payload;
      })
      .addCase(saveStoreInfo.rejected, (state, actions) => {
        state.loadingStore = false;
        state.errorStore = actions.error.message;
      })
      .addCase(editStoreInfo.pending, (state) => {
        state.loadingStore = true;
      })
      .addCase(editStoreInfo.fulfilled, (state, actions) => {
        state.loadingStore = false;
        state.saveStore = actions.payload;
      })
      .addCase(editStoreInfo.rejected, (state, actions) => {
        state.loadingStore = false;
        state.errorStore = actions.error.message;
      })
      .addCase(getStoreByUbicationData.pending, (state) => {
        state.loadingStore = true;
      })
      .addCase(getStoreByUbicationData.fulfilled, (state, actions) => {
        state.loadingStore = false;
        state.store = actions.payload;
      })
      .addCase(getStoreByUbicationData.rejected, (state, actions) => {
        state.loadingStore = false;
        state.store = null;
        state.errorStore = actions.error.message;
      });
  },
});
export const {
  setStore,
  resetStore,
  resetStoreSave,
  resetErrorStore,
  setModeStore,
  resetStores,
} = storeSlice.actions;
export const storeReducer = storeSlice.reducer;

import { request } from "app/utils/http-client";

const URL_GET_COUNTRIES = "/country/allcountries";
const URL_SAVE_COUNTRY = "/country/countries";

export const getCountriesList = () => {
  return request(URL_GET_COUNTRIES, "get");
};

export const saveCountryData = (body) => {
  return request(URL_SAVE_COUNTRY, "post", body);
};

export const editCountryData = (body) => {
  return request(URL_SAVE_COUNTRY, "put", body);
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editTypeDocumentData,
  getTypeDocumentList,
  saveTypeDocumentData,
} from "app/services/typeDocumentService";

const initialState = {
  error: null,
  typeDocuments: [],
  typeDocument: null,
  loadingTypeDocument: false,
  typeDocumentSave: null,
};

export const getTypeDocuments = createAsyncThunk(
  "typeDocument/get",
  async () => {
    const res = await getTypeDocumentList();
    return res.data.result;
  },
);

export const saveTypeDocument = createAsyncThunk(
  "typeDocument/post",
  async (body) => {
    const res = await saveTypeDocumentData(body);
    return res.data.result;
  },
);

export const editTypeDocument = createAsyncThunk(
  "typeDocument/put",
  async (body) => {
    const res = await editTypeDocumentData(body);
    return res.data.result;
  },
);

const typeDocumentSlice = createSlice({
  initialState,
  name: "typeDocument",
  reducers: {
    setTypeDocument(state, actions) {
      const { payload } = actions;
      state.typeDocument = payload;
    },
    resetTypeDocument(state, actions) {
      state.typeDocument = null;
    },
    resetTypeDocumentSave(state, actions) {
      state.categorySave = null;
    },
    resetTypeDocuments(state, actions) {
      state.typeDocuments = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypeDocuments.pending, (state) => {
        state.loadingTypeDocument = true;
      })
      .addCase(getTypeDocuments.fulfilled, (state, actions) => {
        state.loadingTypeDocument = false;
        state.typeDocuments = actions.payload;
      })
      .addCase(getTypeDocuments.rejected, (state, actions) => {
        state.loadingTypeDocument = false;
        state.typeDocuments = [];
        state.error = actions.error.message;
      })
      .addCase(saveTypeDocument.pending, (state) => {
        state.loadingTypeDocument = true;
      })
      .addCase(saveTypeDocument.fulfilled, (state, actions) => {
        state.loadingTypeDocument = false;
        state.typeDocumentSave = actions.payload;
      })
      .addCase(saveTypeDocument.rejected, (state, actions) => {
        state.loadingTypeDocument = false;
        state.error = actions.error.message;
      })
      .addCase(editTypeDocument.pending, (state) => {
        state.loadingTypeDocument = true;
      })
      .addCase(editTypeDocument.fulfilled, (state, actions) => {
        state.loadingTypeDocument = false;
        state.typeDocumentSave = actions.payload;
      })
      .addCase(editTypeDocument.rejected, (state, actions) => {
        state.loadingTypeDocument = false;
        state.error = actions.error.message;
      });
  },
});
export const {
  setTypeDocument,
  resetTypeDocument,
  resetTypeDocumentSave,
  resetTypeDocuments,
} = typeDocumentSlice.actions;
export const typeDocumentReducer = typeDocumentSlice.reducer;

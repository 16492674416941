import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_PROFILES = "/profile/allprofiles";
const URL_SAVE_PROFILE = "/profile";

export const getProfileList = () => {
  return request(URL_GET_PROFILES, httpTypes.GET);
};

export const saveProfileData = (body) => {
  return request(URL_SAVE_PROFILE, httpTypes.POST, body);
};

export const editProfileData = (body) => {
  return request(URL_SAVE_PROFILE, httpTypes.PUT, body);
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editDepartmentData,
  getDepartmentsList,
  getDepartmentsListByCountry,
  saveDepartmentData,
} from "app/services/departmentService";

const initialState = {
  error: null,
  departments: [],
  loadingDepartment: false,
  department: null,
  saveDepartment: null,
};

export const getDepartmentsByCountry = createAsyncThunk(
  "departmentsByCountry/get",
  async (countryId) => {
    const res = await getDepartmentsListByCountry(countryId);
    return res.data.result;
  },
);

export const getDepartments = createAsyncThunk("departments/get", async () => {
  const res = await getDepartmentsList();
  return res.data.result;
});

export const saveDepartments = createAsyncThunk(
  "departments/post",
  async (body) => {
    const res = await saveDepartmentData(body);
    return res.data.result;
  },
);

export const editDepartments = createAsyncThunk(
  "departments/put",
  async (body) => {
    const res = await editDepartmentData(body);
    return res.data.result;
  },
);

const departmentsSlice = createSlice({
  initialState,
  name: "departments",
  reducers: {
    setDepartment(state, actions) {
      const { payload } = actions;
      state.department = payload;
    },
    resetDepartment(state, actions) {
      state.department = null;
    },
    resetDepartments(state, actions) {
      state.departments = [];
    },

    resetDepartmentSave(state, actions) {
      state.saveDepartment = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.loadingDepartment = true;
      })
      .addCase(getDepartments.fulfilled, (state, actions) => {
        state.loadingDepartment = false;
        state.departments = actions.payload;
      })
      .addCase(getDepartments.rejected, (state, actions) => {
        state.loadingDepartment = false;
        state.departments = [];
        state.error = actions.error.message;
      })
      .addCase(getDepartmentsByCountry.pending, (state) => {
        state.loadingDepartment = true;
      })
      .addCase(getDepartmentsByCountry.fulfilled, (state, actions) => {
        state.loadingDepartment = false;
        state.departments = actions.payload;
      })
      .addCase(getDepartmentsByCountry.rejected, (state, actions) => {
        state.loadingDepartment = false;
        state.departments = [];
        state.error = actions.error.message;
      })
      .addCase(saveDepartments.pending, (state) => {
        state.loadingDepartment = true;
      })
      .addCase(saveDepartments.fulfilled, (state, actions) => {
        state.loadingDepartment = false;
        state.saveDepartment = actions.payload;
      })
      .addCase(saveDepartments.rejected, (state, actions) => {
        state.loadingDepartment = false;
        state.error = actions.error.message;
      })
      .addCase(editDepartments.pending, (state) => {
        state.loadingDepartment = true;
      })
      .addCase(editDepartments.fulfilled, (state, actions) => {
        state.loadingDepartment = false;
        state.saveDepartment = actions.payload;
      })
      .addCase(editDepartments.rejected, (state, actions) => {
        state.loadingDepartment = false;
        state.error = actions.error.message;
      });
  },
});

export const {
  setDepartment,
  resetDepartment,
  resetDepartmentSave,
  resetDepartments,
} = departmentsSlice.actions;
export const departmentsReducer = departmentsSlice.reducer;

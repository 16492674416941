import { Loading } from "app/components";
import {
  getStoreByUbicationData,
  resetStore,
} from "app/redux/slices/store/storeSlice";
import { loginUser } from "app/services/loginService";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (
  accessToken,
  username,
  companyId,
  userId,
  storeId,
  isVirtual
) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("username", username);
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("userId", userId);
    localStorage.setItem("storeId", storeId);
    localStorage.setItem("isVirtual", isVirtual);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("companyId");
    localStorage.removeItem("userId");
    localStorage.removeItem("storeId");
    localStorage.removeItem("isVirtual");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, user, isAuthenticated, isInitialised: true };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchReduce = useDispatch();
  const { store } = useSelector((state) => state.store);

  const login = async (userName, pass, location) => {
    const body = {
      user: userName,
      password: pass,
    };
    const { data } = await loginUser(body);
    const { result, user } = data;
    const newData = parseLoginResponse(result);
    const params = {
      siteType: newData[1],
      longitud: "-74.7896832",
      latitud: "10.9576192",
    };
    dispatchReduce(getStoreByUbicationData(params));
    setSession(newData[0], newData[2], newData[1], newData[3], null, null);
    dispatch({
      type: "LOGIN",
      payload: { user: { name: newData[2], avatar: "" } },
    });
  };

  useEffect(() => {
    if (store !== null) {
      localStorage.setItem("storeId", store.storeId);
      localStorage.setItem("isVirtual", store.isVirtual);
    }
    return () => dispatch(resetStore());
  }, [store]);

  const parseLoginResponse = (data) => {
    return data.split("|");
  };

  const register = async (email, username, password) => {
    const { data } = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });
    const { accessToken, user } = data;
    setSession(accessToken, null, null, null, null, null);
    dispatch({
      type: "REGISTER",
      payload: { user: { name: "prueba", avatar: "" } },
    });
  };

  const logout = () => {
    setSession(null, null, null, null, null, null);
    dispatch({ type: "LOGOUT" });
  };

  // console.log("jwt", state);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const username = window.localStorage.getItem("username");
        const companyId = window.localStorage.getItem("companyId");
        const userId = window.localStorage.getItem("userId");
        const storeId = window.localStorage.getItem("storeId");
        const isVirtual = window.localStorage.getItem("isVirtual");
        if (accessToken && isValidToken(accessToken)) {
          setSession(
            accessToken,
            username,
            companyId,
            userId,
            storeId,
            isVirtual
          );
          //const response = await axios.get("/api/auth/profile");
          // const { user } = response.data;
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user: { name: username, avatar: "" },
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) return <Loading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
